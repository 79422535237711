import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { mapLanguage } from '../project.config';

type Props = {
  seoTitle: string;
  seoDescription: string;
  canonicalUrl: string;
  image: string;
  locale: string;
};

const excludedPaths = ['/login', '/account', '/cart'];

export const MetaTags: React.FC<Props> = ({ seoTitle, seoDescription, canonicalUrl, image, locale }) => {
  const mapLocale = mapLanguage(locale ?? 'de');
  const { asPath } = useRouter();
  let [path] = asPath.split('?');

  if (path.startsWith('/search/')) {
    path = '/search';
  }

  const isExcluded = excludedPaths.some((excluded) => path.includes(excluded));

  return (
    <Head>
      {seoTitle && <title key="title">{seoTitle}</title>}
      {seoDescription && <meta name="description" content={seoDescription} key="description" />}

      {!isExcluded && (
        <>
          <meta property="og:site_name" content="JobRad® Loop" />
          {seoTitle && <meta property="og:title" content={seoTitle} key="og:title" />}
          {seoDescription && <meta property="og:description" content={seoDescription} key="og:description" />}
          <meta property="og:type" content="website" key="og:type" />
          {canonicalUrl && <meta property="og:url" content={canonicalUrl} key="og:url" />}
          <meta property="og:image" content={image} key="og:image" />
          <meta property="og:locale" content={mapLocale} key="og:locale" />
        </>
      )}
    </Head>
  );
};
